import React, { useEffect, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { motion, useViewportScroll, transform } from 'framer-motion'

import { Layout, PageHero, PageSection } from '../components'

import { container, padding, colours, type, bgImage, pagePaddingTop, wrapper } from '../styles/global'
import { media } from '../styles/utils'
import { parseACF } from '../utils'
import { useScrollAnim } from '../utils/anim'
import { subscribeContext } from '../components/Subscribe/SubscribeProvider'
import { forEach } from 'lodash'

const Hello = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)
    const subscribe = useContext(subscribeContext)

    console.log(data)

	const renderSections = () => {
		if (!data.sections) return;

		const items = data.sections.map((item, i) => {
            console.log(item)

            let sectionItems = []
            
            const links = item.links.map((link, i) => {
                if (!link) return;
                
                let as;
                if (link.type == 'url' || link.type == 'email') as = 'a'
                if (link.type == 'internal') as = Link

                let href = link.url
                if(link.type == 'email') href = `mailto:${link.email}`

                return (
                    <ItemLink
                        key={i}
                        as={as}
                        to={link.link}
                        href={href}
                        target={link.type == 'url' && '_blank'}
                        onClick={() => {
                            if (link.type == 'insights_subscribe') {
                                subscribe.setDefaultList('insights')
                                subscribe.setActive(true)
                                subscribe.setUseSocialField(true)
                            }

                            if (link.type == 'live_subscribe') {
                                subscribe.setDefaultList('live')
                                subscribe.setActive(true)
                                subscribe.setUseSocialField(true)
                            }
                        }}
                    >
                        {link.title}
                    </ItemLink>
                )
            })

            const section = {
                title: item.title,
                renderContent: () => {
                    return (
                        <Links>
                            {links}
                        </Links>
                    )
                }
            }	
    
            return (
                <PageSection 
                    key={i}
                    disableSidebarTitleFade={true}
                    disableTextAnimation={true}
                    {...section}
                />
            )
		})

		return items
    }
    
    const renderSubscribe = (params) => {
        const section = {
            title: 'Subscribe',
            renderContent: () => {
                return (
                    <SubscribeContent>
                        <Subheading
                            onClick={() => {
                                subscribe.setDefaultList('insights')
                                subscribe.setActive(true)
                            }}
                        >
                            News + Insights
                        </Subheading>
                        <Subheading
                            onClick={() => {
                                subscribe.setDefaultList('live')
                                subscribe.setActive(true)
                            }}
                        >
                            CR/Live
                        </Subheading>
                    </SubscribeContent>
                )
            }
        }	

        return (
            <PageSection 
                {...section}
            />
        )
    }

	return (
		<Layout
            meta={data.seo}
            hideMenu
            hideFooter={!data.footer_display}
        >
			<Wrapper>
                <Container>
                   {renderSections()}
                   {/* {renderSubscribe()} */}
                </Container>
			</Wrapper>
		</Layout>	
	)
}


// Shared

const Heading = styled(motion.h2)``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Video = styled(ReactPlayer)``

// Layout

const Wrapper = styled.div`
	${wrapper}
`

const Container = styled.div`
	${container}
	${padding}
	${pagePaddingTop}
	display: flex;
	flex-direction: column;

    ${media.phone`
        padding-top: 100px;
    `}

    ${media.phone`
        .intro p {
            word-break: break-word;
        }
    `}
`

// Link

const ItemLink = styled.div`
    ${type.heading3};
    cursor: pointer;
    background-size: 1px 1em;
    box-shadow:
        inset 0 0em black,
        inset 0 -0.05em ${colours.lemon};
`

const Links = styled.div`
    display: flex;
	flex-direction: column;
    align-items: flex-start;
    margin-bottom: 64px;

    ${media.phone`
        &:last-child {
            margin-bottom: 32px !important;
        }
    `}
`

// Subscribe

const SubscribeContent = styled.div`
	display: flex;
	flex-direction: column;
    align-items: flex-start;

    ${Subheading} {
        ${type.heading3};
        background-size: 1px 1em;
        box-shadow:
            inset 0 0em black,
            inset 0 -0.05em ${colours.lemon};
    }
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "hello" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`

export default Hello